import React from 'react'
import Cards from '../Cards/Cards'

function Projects() {
    return (
        <div className="projects" id="projects">
            <Cards/>
        </div>
    )
}

export default Projects

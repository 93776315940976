import React from 'react'
import Contact from '../ContactForm/Contact'

function ContactMe() {
    return (
        <div className="contact" id="contact">
            <Contact/>
        </div>
    )
}

export default ContactMe
